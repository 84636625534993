h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $Larsseit;
  text-rendering: optimizeLegibility;
  margin-top: 0;
  margin-bottom: 0.3125rem;
  font-weight: bold;
  color: var(--n600);
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 1rem;

  &.reg {
    font-weight: 400;
    color:var(--n200);
  }
}
h6 {
  font-size: 0.8125rem;
  text-transform: uppercase;
}
p {
  margin-bottom: 1.25rem;
  margin-top: 0;

  &.lead {
    color: var(--n200);
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: $regular;

    @media (max-width: 37.5rem) {
      margin-bottom: .625rem;
    }
  }
}
ul {
  padding-left: 0;
}
ul {
  li {
    padding-left: 0;
    list-style: none;
  }
}
ol {
  li {
    padding-left: 0;
  }
}
a {
  font-size: .875rem;
  text-decoration: none;
  color: var(--b400);
  outline: 0;

  &:hover {
    background: none;
    color: var(--g300);
  }
}
:focus {
  outline: none !important;
}
label {
  font-size: .875rem;
  font-weight: $medium;
  color: var(--n600);
  margin-bottom: 0.5rem;
  display: inline-block;

  small {
    display: block;
    font-size: .75rem;
    color: #d1d1d1;
    font-weight: 400;
  }
}
[type="text"],
[type="password"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="month"],
[type="week"],
[type="email"],
[type="number"],
[type="search"],
[type="tel"],
[type="time"],
[type="url"],
[type="color"],
textarea {
  height: 3rem;
  width: 100%;
  font-size: 0.875rem;
  padding: 0.75rem 1rem;
  border: 0.0625rem solid var(--n60);
  border-radius: 0.25rem;
  font-family: $Larsseit;
}
.p-dropdown {
  border: 0.0625rem solid var(--n60);
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-family: $Larsseit;
}
textarea {
  height: auto;
}
.vh {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-green {
  color:var(--g300) !important;
}
.text-purple {
  color: var(--b300) !important;
}
.p-inputtext,
.p-link {
  font-family: $Larsseit;
}