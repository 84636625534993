.sd-tag {
  padding: .1875rem .25rem;
  height: 1.25rem;
  font-size: .875rem;
  color: var(--n0);
  font-weight: $regular;

  &.btn-sd {
    height: 2.25rem;
    border-radius: 1.125rem;
    padding: .6875rem 1.25rem;
    font-size: .875rem;
    font-weight: $bold;
    color: var(--b400);
    background: var(--b20);
    cursor: pointer;

    &.active,
    &:hover,
    &:focus,
    &:active {
      color:var(--n0);
      background: var(--b400);
    }
  }
  &.p-tag-rounded {
    border-radius: .25rem !important;
  }
  &.tag-black {
    background-color:var(--n500);
  }
  &.tag-grey {
    background-color:var(--n100);
  }
  .p-tag-icon {
    &:before {
      font-size: 1rem;
    }
  }
}