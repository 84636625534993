.card-listing {
  @media (min-width: $width-md) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5625rem;
  }
  .card-item {
    @media (min-width: $width-md) {
      flex: 0 0 50%;
      padding: 0 0.5625rem;
      margin-bottom: 1.125rem;
    }
    .p-card {
      background: var(--n0);
      box-shadow: 0px 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
      border-radius: 0.25rem;
      margin-bottom: 1.25rem;
      color: var(--n600);

      @media (min-width: $width-md) {
        height: 100%;
        margin-bottom: 0;
      }
      &-body {
        padding: 2rem;
      }
      &-content {
        padding: 0;
      }
    }
  }
  .card-workspace {
    order:1;

    
    .p-card {
      border: 2px solid transparent;

      &:hover {
        box-shadow: 0px 0.5rem 1.25rem 0.25rem rgba(0, 0, 0, 0.1);
        border-radius: 0.25rem;

        .p-card-body {
          .card-title {
            h4 {
              color: var(--b400);
            }
            .p-button {
              color: var(--n500);
            }
          }
        }
      }
      &-body {
        padding-left: 8rem;
        position: relative;

        .avatar {
          position: absolute;
          top: 2rem;
          left: 2rem;

          .p-avatar {
            width: 5rem;
            height: 5rem;
            font-weight: $bold;
          }
        }
        .card-title {
          margin-bottom: 0.25rem;
          padding-top: 1.5rem;
          position:relative;
          margin-top: -0.75rem;

          .badge {
            position:absolute;
            top:0;
            left:0;
          }
          h4 {
            color: var(--n600);
            margin: 0;

            strong {
              display: inline-block;
              vertical-align: top;
              margin-top: 0.3125rem;
              padding: 0.1875rem 0.25rem;
              background: var(--b400);
              border-radius: 0.25rem;
              font-size: 0.875rem;
              line-height: 0.875rem;
              font-weight: $regular;
              color: var(--n0);
              margin-left: 0.625rem;
            }
          }
          .card-menu {
            margin-top:-1.5rem;
          }
          .p-button {
            width: 1.25rem;
            height: 1.25rem;
            color: var(--n100);
          }
        }
        p {
          margin: 0 0 0.25rem 0;
          font-size: 0.8125rem;

          &.active-status {
            color: var(--n100);
            margin-bottom: 2.5rem;
          }
        }
        .card-footer {
          display: flex;
          align-items: center;

          small {
            font-size: 0.6875rem;
            color: var(--n200);
            margin-left: 0.5rem;
          }
          .badge {
            position: relative;
            z-index: 1;
            margin-left: auto;
          }
        }
        .user-avatar {
          margin-right: 0.25rem;
          height: 1.5rem;
          width: 1.5rem;
          font-size: 0.625rem;
          line-height: 0.625rem;

          img {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
          }
          .p-avatar {
            width: 1.5rem;
            height: 1.5rem;
            background: var(--b10);
            font-size: 0.625rem;
            line-height: 0.625rem;
            color: var(--b400);
            font-weight: $bold;
            text-align: center;
          }
        }
      }
    }
    &.current-workspace {
      order: 0;

      .p-card {
        border-color: var(--y60);
      }
    }
    &.deactivated-workspace {
      order: 2;

      .p-card {
        position: relative;

        &:hover {
          box-shadow: 0px 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
  
          .p-card-body {
            .card-title {
              h4 {
                color: var(--n600);
              }
              .p-button {
                color: var(--n500);
              }
            }
          }
        }
        &:after {
          position:absolute;
          background: var(--n0);
          opacity: 0.5;
          left:0;
          top:0;
          width: 100%;
          height: 100%;
          display:block;
          content:'';
        }
        &-body {
          .card-title {
            .card-menu {
              position: relative;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}
