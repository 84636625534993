#Footer {
  color: var(--n400);
  padding: 1rem .5rem;
  background-color: var(--n20);
  z-index: 1;
  margin-top: auto;

  @media (min-width: $width-sm) {
    padding: 2rem 0;
  }

  &.bg-white {
    background-color: var(--n0);
  }
  p {
    margin: 0;
    font-size: .8125rem;
    font-weight: $bold;
    color: var(--n500);

    a {
      color: var(--n500);
    }
    small {
      color: var(--n200);
      font-weight: $regular;
      font-size: .8125rem;
      display:block;

      @media (min-width: $width-sm) {
        display: inline;
      }
    }
  }
}
