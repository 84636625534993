.section-nav {
  margin-bottom: 1rem;

  &.wmt {
    margin-top: 1.5rem;

    @media (min-width: $width-md) {
      margin-top: 3rem;
    }
  }

  .btn-menu {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    text-align: left;
    padding-right: 0.625rem;

    @media (min-width: $width-md) {
      display: none;
    }
    .p-button-icon {
      order: 1;
      margin: 0;
    }
    .p-button-label {
      order: 0;
    }
  }
  .subnav-menu {
    .p-menu {
      width: auto;
      right: 0.9375rem;
    }
  }
}
