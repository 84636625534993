.sm-centered_container {
  @media (min-width: 48rem) {
    width: 540px;
    margin: 0 auto;
  }
}
.page-404 {
	text-align: center;
	padding-top: 10vh;
	padding-bottom: 10vh;

	h1 {
		font-weight: $medium;
		font-size: 144px;
		line-height: 179px;
		color: var(--n600);
		font-family: $Larsseit;
		margin-bottom: 20px;

		img {
			width: 108px;
			display: inline-block;
			margin: -20px 15px 0;
		}
	}
	p {
		color: var(--n600);
		font-size: $body-font-size;
		font-family: $Larsseit;

		strong {
			display: block;
			font-size: 24px;
			font-weight: $bold;
		}
	}
}
