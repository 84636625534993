.section-top {
  padding-bottom: 0.5rem;

  &.stickied {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    margin-bottom: 0;
  }
  h1 {
    margin-top: 0.5625rem;
    margin-bottom: 0;
    font-weight: $regular;
    line-height: 3rem;
  }
  &.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.875rem;

    @media (min-width: $width-sm) {
      padding-bottom: 3.75rem;
    }
    h1 {
      margin: 0;
    }
  }
  &.back-button {
    .btn-back {
      margin-bottom: 1.875rem;
    }
    h1 {
      margin: 0;
      font-size: 2rem;

      @media (min-width: $width-sm) {
        font-size: 2.5rem;
      }
    }
  }
  &.add-button {
    overflow: hidden;
    align-items: flex-start;

    h1 {
      font-size: 1.5rem;
      line-height: normal;
      margin-right: 10px;

      @media (min-width: $width-sm) {
        font-size: 2.5rem;
      }
    }
    .btn-sd {
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.project-overview {
  @media (min-width: $width-sm) {
    margin-bottom: 0;
  }
  .section-top {
    margin-bottom: 3.125rem;

    h6 {
      margin-bottom: 0.5rem;
      text-transform: uppercase;
      font-weight: $regular;
      letter-spacing: 0.0625rem;
    }
    h1 {
      margin: 0 0.875rem 0 0;
      line-height: 3rem;
      font-weight: $regular;
    }
    .project-tracking-status {
      font-size: 0.8125rem;
      margin: 0 0 0 1.25rem;
      color: var(--n200);
    }
  }
  .project-archived {
    h1 {
      color: var(--n100);
    }
  }
}
.page-timer {
  .section-top {
    margin-top: -5.5rem;
  }
}
.section-top-history {
  &.flex {
    padding-bottom: 2rem;
    align-items: flex-start;

    .time-filter {
      flex-wrap: wrap;
      justify-content: end;
      width: 9.75rem;
      padding-top: 1rem;

      @media (min-width: $width-xs) {
        width: auto;
        padding-top: 0;
        align-items: center;
      }
    }
    .time-range {
      text-align: left;
      margin-bottom: 0.375rem;

      @media (min-width: $width-xs) {
        margin-right: 1rem;
      }
      h6 {
        font-size: 0.625rem;
        font-weight: $medium;
        color: var(--n100);
        text-transform: none;
        margin-bottom: 0.25rem;
      }
      p-button {
        line-height: 1rem;
        display: block;
      }
      .btn-dd {
        padding: 0;
        font-size: 1rem;
        line-height: 1rem;
        width: 9.75rem;
        text-align: left;
        color: var(--n500);

        .p-button-icon {
          font-size: 0.625rem;
        }
      }
    }
    .btn-ghost {
      width: 2rem;
      margin-left: 0.375rem;
    }
  }
}
.section-top-analytics {
  &.flex {
    padding-bottom: 1.5rem;

    @media (max-width: 37.5rem) {
      flex-wrap: wrap;
    }
  }
}
.time-range-wrapper {
  &.time-filter {
    @media (max-width: 37.5rem) {
      width: 90vw !important;
    }
    .time-range {
      @media (max-width: 37.5rem) {
        justify-content: start;
        width: 100%;
      }
      .btn-dd {
        @media (max-width: 37.5rem) {
          width: 100% !important;
        }
      }
    }
  }
}

