.with_range {
  position:relative;
  padding-right:60px;

  @media (min-width: $width-sm) {
    margin-top:5px;
  }
}
.range {
  position:relative;
  white-space:nowrap;
  height:30px;
  border-right:1px solid #EAEAEA;
  padding-right:10px;

  @media (min-width: $width-sm) {
    width:auto;
  }

  span {
    display:inline-block;
    width: calc(100% - 20px);
    text-overflow:ellipsis;
    overflow:hidden;
    font-size:14px;
    font-weight:$bold;
    vertical-align:middle;
    cursor: pointer;

    @media (min-width: $width-sm) {
      width:auto;
      margin-right:10px;
      font-size:20px;
    }
    @media (min-width: $width-md) {
      font-size:24px;
    }
  }
  i {
    color:#A3A3A3;
    font-size:12px;
    line-height:1.5;
    vertical-align:middle;
    cursor: pointer;
  }
}
.range_nav {
  font-size:0;
  line-height:0;
  text-align:right;
  position:absolute;
  right:0;
  top:28px;

  @media (min-width: $width-sm) {
    top:8px;
  }
  @media (min-width: $width-md) {
    top:10px;
  }

  a {
    padding:5px;
  }
  i {
    color:#A3A3A3;
    font-size:12px;
    line-height:normal;
  }
}

.load-more {
  color: var(--n0);
  display: block;
  text-align: center;
  margin-top: 20px;
}
.report_table {

  thead {
    tr {
      display: block;
      font-size: 0;
      @media (min-width: $width-md) {
        display: table-row;
      }

      &.time_edit::before,
      &.time_edit::after {
        display:none;
        clear:none;
      }

      th {
        display: none;
        padding: 10px;
        font-size: 14px;
        // white-space: nowrap;
        @media (min-width: $width-md) {
          display: table-cell !important;
          float: none !important;
        }

        > i {
          font-size:12px;
          color: #A3A3A3;
          cursor: pointer;
          @media (min-width: $width-lg) {
            font-size: 14px;
          }
        }
        &.headerSortDown {
          .icon-sort-up {
            display:none;
          }
        }
        &.headerSortUp {
          .icon-sort-down {
            display:none;
          }
        }

        &.checkbox {
          display: inline-block;
          padding-right: 0;
          width: 50px;
          float: left;
          @media (min-width: $width-md) {
            padding: 15px 0 15px 15px;
          }

          .cb {
            padding: 0;
            width: 16px;
            height: 16px;
            display: inline-block;
            vertical-align: middle;
            span {
              &:before,
              &:after {
                left: 0;
              }
            }
          }
          .dropdown_item {
            display: inline-block;
            font-weight: 400;
            .dropdown-pane {
              right: auto;
              top: auto;
            }
          }
          i {
            color: var(--light-grey)-arrow;
            font-weight: 400;
            font-size: 10px;
          }
        }

        &.c1 {
          display: inline-block;
          float: left;
          @media (max-width: 1023px) {
            width: 50%;
          }
        }

        &.c2,
        &.c3 {
          width: 110px;
          @media (min-width: $width-lg) {
            width: 160px;
          }
          @media (min-width: 1400px) {
            width: 230px;
          }
        }
        &.c_team {
          width: 110px;
        }
        &.c4 {
          width: 200px;
        }
        &.c5 {
          width: 125px;
          display: inline-block;
          float: right;

          @media (max-width: $width-xs) {
            display: none;
          }
          @media (max-width: $width-md) {
            margin-right: 40px;
          }
        }
        &.c6 {
          width: 30px;
        }
        &.c7 {
          width: 20px;
          @media (min-width: $width-md) {
            width: 30px;
          }
        }
        &.c8 {
          width: 20px;
          @media (min-width: $width-md) {
            width: 40px;
          }
        }
      }
    }
  }

  tr {
    @media (min-width: $width-lg) {
      display:table-row !important;
      position:static !important;
    }

    &:hover {
      .c6 {
        a {
          display:none !important;
        }
      }
    }
    &.time_edit {
      .c6 {
        a {
          display:inline-block !important;
        }
      }
    }
  }

  tbody {
    tr {
      @media (max-width: 1023px) {
        padding-bottom: 28px;
        display: block;
        position: relative;
      }
      &.time_edit {
        @media (max-width: $width-xs) {
          padding-bottom: 30px;
        }
        td {
          &.c1 {
            position: relative;
            width: 40%;
            @media (min-width: $width-md) {
              width: auto;
            }
            input {
              margin-left: 50px;
              position: absolute;
              width: 80%;
              @media (min-width: $width-md) {
                margin-left: 0;
                position: static;
                width: 100%;
              }
            }
            .chosen_wrap {
              margin-left: 50px;
              margin-top: 25px;
            }
          }
          &.c_team {
            display: none;
            @media (min-width: $width-md) {
              display: table-cell;
            }
          }
          &.c4 {
            @media (min-width: $width-md) {
              padding-top: 20px;
            }
          }
          &.c8 {
            @media (max-width: $width-md) {
              right: 115px;
            }
            @media (min-width: $width-xxs) {
              right: 135px;
            }
            @media (min-width: $width-xs) {
              right: 160px;
            }
          }
        }
      }
      td {
        &.c1 {
          padding-left: 10px;
          @media (max-width: 1023px) {
            width: 50%;
          }
          @media (max-width: $width-sm) {
            width: 55%;
          }
          @media (max-width: $width-xs) {
            width: 50%;
          }
          .ellipsis {
            padding-left: 10px;
            @media (min-width: $width-md) {
              padding: 0;
            }
          }
        }
        &.c2,
        &.c3 {
          width: 160px;
          padding-right: 10px;
          
          @media (max-width: $width-lg) {
            width: 120px;
          }
          @media (max-width: 1023px) {
            display: none !important;
          }
          @media (min-width: 1400px) {
            width: 230px;
          }

          span {
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            overflow: hidden;
          }
        }
        &.c_team {
          width: 110px;
          @media (max-width: 1023px) {
            position: absolute;
            left: 0;
            bottom: 0;
            margin-left: 50px;
            width: auto;
          }
        }
        &.c4 {
          width: auto;
          text-align: left;
          margin-left: 50px;
          @media (min-width: $width-sm) {
            margin-left: 40px;
          }
          @media (min-width: $width-md) {
            padding-left: 10px;
          }
          @media (min-width: 1400px) {
            width: 240px;
          }
          span {
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            overflow: hidden;
          }
        }
        &.c5 {
          // width: 50px;
          @media (min-width: $width-md) {
            // width: 130px;
            padding-right:0;
            text-align:center;
          }
        }
        &.c8 {
          width: 20px;

          @media (max-width: $width-md) {
            position: absolute;
            width: 40px;
            top: 1px;
            right: 135px;
            font-size: 12px;
          }
          @media (min-width: $width-xs) {
            right: 160px;
          }
          @media (min-width: $width-xl) {
            width: 60px;
            text-align: center;
            // padding-right: 20px;
          }
          button {
            cursor: pointer;
            margin: auto;
          }
        }
      }
      .checkbox {
        padding:10px 0 10px 10px;
        float: left;
        width: 50px;
        @media (min-width: $width-md) {
          padding: 15px 0 15px 15px;
          width: 40px;
          float: none;
        }

        .cb {
          padding:0;
          height:16px;
          width:16px;
          float:left;
          z-index: 1;

          span {
            &:before,
            &:after {
              left:0;
            }
          }
        }
        .dropdown_item {
          float:left;
          line-height:14px;
          padding:0;
          margin-left: 5px;
          display: inline-block;

          .dd {
            font-size:10px;
            line-height:14px;
            color:#A3A3A3;
          }

          .dropdown-pane {
            left:0;
            right:auto;
            top:100%;
            min-width:195px;

            ul {
              li {
                a {
                  font-weight:normal;
                  padding-top:10px;
                  padding-bottom:10px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.public-table {
    thead {
      .c4 {
        width: 210px;
        @media (max-width: $width-md) {
          // margin-right: 0;
          // width: auto;
        }
      }
      .c5 {
        width: 110px;
        @media (max-width: $width-md) {
          margin-right: 0;
          width: auto;
        }
      }
    }
    tbody {
      .c1  {
        @media (max-width: $width-md) {
          padding-left: 0;
          width: 70%;
        }
      }
      .c2 {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .c_team {
        @media (max-width: $width-md) {
          margin-left: 0;
          width: 70%;
          text-align: left;
        }
      }
      .c4 {
        @media (max-width: $width-md) {
          margin-left: 0;
          padding-left: 10px;
        }
      }
      .c5 {
        @media (max-width: $width-md) {
          right: 0;
          width: 30%;
        }
      }
    }
  }
}
.section {
  &__chart {
    padding-bottom: 30px;
    background: var(--n0);

    .section-clearer {
      clear: both;
      display: inline-block;
      position: relative;
      width: 100%;
      padding: 14px 16px 0;
      @media (min-width: $width-md) {
        padding: 28px 32px 0;
      }
    }
    .graph_count {
      font-family: $Larsseit;
      margin-bottom: 15px;
      @media (min-width: $width-xxs) {
        float: left;
      }
      small {
        color: var(--grey);
        font-size: 14px;
        display: block;
      }
      span {
        font-size: 24px;
        color: var(--n600);
        font-weight: $bold;
      }
    }
    .graph_utils {
      @media (min-width: $width-xxs) {
        float: right;
      }
      .print {
        display: inline-block;
        vertical-align: middle;
        // border-right: 1px solid var(--light-grey);
        padding-right: 15px;
        margin-right: 15px;
        height: 50px;
        line-height: 50px;
        a {
          color: var(--print-grey);
          color: var(--n0);
          font-size: 20px;
        }
      }
      .export {
        // display: inline-block;
        vertical-align: middle;
        font-size: 0;
        .btn-sd {
          color: var(--g400);
          display: inline-block;
          font-size: 16px;
          padding: 6px 30px;
          border: 2px solid var(--g400);
          margin-right: 10px;
          &:hover {
            background: var(--g400);
            color: var(--n0);
          }
        }
        .dropdown {
          &_item {
            display: inline-block;
            .dd {
              border: 2px solid var(--g400);
              // height: 40px;
              // line-height: 40px;
              border-radius: 5px;
              padding: 6px 38px 6px 15px;
              font-size: 16px;
              font-weight: bold;
              display: block;
              position: relative;

              span {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 14px;
              }
              &.dd {
                &-open {
                  background: var(--g300);
                  color: var(--n0);
                  border: 2px solid transparent;
                  &:focus, &:active {
                    color: var(--n0);
                  }
                }
              }
              &:focus, &:active {
                color: var(--g400);
              }
            }
            .dropdown-pane {
              top: 40px;
              right: 0;
              left: auto;
            }
          }
        }
      }
    }
    #bar{
      &_chart {
        &--daily, &--monthly, &--yearly {
          overflow-x: scroll;
          overflow-y: hidden;
          @media (min-width:1920px) {
            overflow-x: hidden;
          }
        }
      }

    }
  }

}
