.table_workspace {
  td {
    &:first-child {
      width:50%;

      @media (max-width: $width-xs) {
        width: 60%;
      }

      @media (min-width: $width-md) {
        width:auto;
      }
    }
    &:nth-child(2) {
      @media (min-width: $width-md) {
        width:350px;
      }
    }
  }
  td {
    &:last-child {
      width:30px;
    }
  }
  th:nth-child(3),
  td:nth-child(3) {
    @media (max-width: $width-xs) {
      display: none;
    }
    @media (min-width: $width-md) {
      width:350px;
    }
  }
}
.table_workspace_team {
  td {
    &.team_admin {
      font-weight: 700;
      color: var(--g300);
    }
  }
  @media (max-width: $width-xs) {
    th:nth-child(3),
    td:nth-child(3) {
      display: none;
    }
  }
}
.list_team {
  margin:0 0 20px 0;
  list-style:none;
  max-height:240px;
  overflow:auto;

  @media (min-width: $width-xs) {
    max-height:300px;
  }
  li {
    display:block;
    margin-bottom:5px;

    a {
      display:block;
      padding:10px;
      box-shadow: 0px 1px 2px 0 rgba(0,0,0,0.16);
      color:#3B3B3B;
      font-size:14px;
      text-overflow:ellipsis;
      overflow:hidden;
      white-space:nowrap;

      @media (min-width: $width-xs) {
        padding:15px;
      }

      &.selected {
        box-shadow:none;
        background:#F0F0F0;
      }
    }
  }
}
