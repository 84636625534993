#FocusHours {
  margin: 80px 0 30px;
  padding: 0 30px;
  
  @media (min-width: $width-sm) {
    display: block;
    font: 0/0 a;
    height: 100px;
  }

  .FocusHours {

    &__meta {
      margin-bottom: 30px;

      @media (min-width: $width-sm) {
        display: inline-block;
        width: 120px;
        margin-right: 40px;
        margin-bottom: 0;
        vertical-align: top;
      }

      h2 {
        font-size: 32px;
        line-height: 38px;
        font-weight: $bold;
        color: var(--charade);
      }

      h3 {
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        color: var(--n200);
      }

      h5 {
        font-size: 14px;
        line-height: 17px;
        font-weight: $bold;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin-bottom: 15px;
      }

    }

    &__chart-container {
      background: var(--n0);
      box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 0 50px 0 100px;
      position: relative;
      height: 96px;
      @media (min-width: $width-sm) {
        display: inline-block;
        width: calc(100% - 160px);
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 100px;
        height: 42px;
        width: calc(100% - 150px);
        border: 1px solid var(--n40);
        border-top: none;
      }

      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        left: 26px;
        top: 50%;
        transform: translateY(-50%);
      }

      #FocusHours_Chart {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 42px;
          width: 25%;
          border: 1px solid var(--n40);
          border-top: none;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          height: 42px;
          width: 25%;
          border: 1px solid var(--n40);
          border-top: none;
        }

        > div {
          z-index: 1;
        }
      }
    }
  }
}