.history-head {
  width: 100%;

  &-title {
    h6 {
      font-size: 0.75rem;
      line-height: 0.75rem;
      font-weight: $regular;
      text-transform: none;
      color: var(--n500);
      margin-bottom: .5rem;
    }
    h3 {
      font-size: 1.75rem;
      line-height: 1.75rem;
      color: var(--n600);
      margin:0;
      font-weight: $regular;
      white-space: nowrap;
    }
  }
  &-button {
    text-align: right;

    .p-button {
      margin-left: .75rem;
      margin-bottom: .625rem;
      font-weight: $bold;

      @media (min-width: 48rem) {
        margin-bottom:0;
      }
      &-icon {
        font-size: 1rem;
      }
    }
  }
}
.chart-title {
  display: inline-flex;
}
.chart-filter {
  margin-bottom: 1rem;

  label {
    margin-right: .625rem;
    font-size: .75rem;
    font-weight: $regular;
    margin-bottom: 0;
    color: var(--n500);
  }
}
.grouping {
  text-align: right;

  label {
    margin:0 .5625rem 0 0;
    font-size: .75rem;
    font-weight: 400;
  }
}