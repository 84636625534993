.form_invite {
  padding-right: 5.625rem;
  position: relative;

  @media (min-width: $width-sm) {
    padding-right: 11.25rem;
    width: 32.5rem;
  }
  input {
    margin: 0;

    @media (min-width: $width-xs) {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
    }
    @media (min-width: $width-sm) {
      padding: 0.8125rem 0.9375rem;
      height: auto;
    }
  }
  .button {
    padding: 0.625rem 1.25rem;
    font-size: 0.875rem;
    position: absolute;
    right: 0;
    bottom: 0;

    @media (min-width: $width-sm) {
      padding: 0.625rem 0;
      width: 10.625rem;
      text-align: center;
      font-size: 1.125rem;
    }
  }
}
