.box {
  padding: 1.6875rem;
  border-radius: 0.25rem;

  &-highlight {
    background: var(--lb50);
    border: 0.0625rem solid var(--lb60);

    i {
      color: var(--lb60);
    }
    a {
      color: var(--b400);
      cursor: pointer;
      font-size: 1rem;
    }
  }
  &-alert {
    background: var(--y50);
    border: 0.0625rem solid var(--y60);

    i {
      color: var(--y60);
    }
  }
  h4 {
    margin-bottom: 1.25rem;
  }
  &.box-delete {
    h4 {
      margin-bottom: 0.625rem;
    }
    .helper {
      margin-bottom: 1.5rem;
    }
    .p-inputtext {
      width: 13.9375rem;
    }
    label {
      margin-bottom: .5rem;
    }
  }
  &.with-icon {
    padding: 1rem .5rem 1rem 3rem;
    position:relative;

    i {
      position: absolute;
      left: 1rem;
      top: .75rem;
      font-size: 1.25rem;
    }
  }
}
.box-cc {
  padding: 3.375rem 1rem 1rem 1rem;
  background: var(--n0);
  border-radius: 0.5rem;
  border: 0.125rem solid var(--n40);
  width: 16.25rem;
  min-height: 9.8125rem;
  position: relative;

  &.default {
    border-color: var(--y60);
  }
  &:hover {
    background-color: #fafafa;
  }
  .p-badge {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
  .card-menu {
    position: absolute;
    top: 0.5rem;
    right: 1rem;

    .p-button-link {
      color: var(--n500);
      font-size: 0.75rem;
      width: 1.25rem;
      height: 1.25rem;
      padding: 0;
    }
  }
  h4 {
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--n500);
    font-weight: $medium;
    margin-bottom: 0.625rem;
  }
  .card-details {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;

    .card-valid {
      padding-left: 0.625rem;
    }

    h6 {
      text-transform: none;
      color: var(--n500);
      font-weight: $medium;
      font-size: 0.8125rem;
      line-height: 1.125rem;
      margin-bottom: 0.25rem;
    }
    p {
      margin: 0;
      font-size: 1rem;
      font-weight: $medium;
      line-height: 1.5rem;

      i {
        font-size: 1.5rem;
        color: var(--n500);
        margin-right: 0.5rem;
        display: inline-block;
        vertical-align: top;
      }
    }
  }
  &.box-add {
    border-style: dashed;
    min-height: 9.8125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    cursor: pointer;

    p {
      margin: 0;
    }
  }
}
