.checklist {
  @media (max-width: 47.9375rem) {
    display: none;
  }
  position:fixed;
  z-index:1001;
  left: 1.5rem;
  bottom: 1.5rem;
  width: 22.5rem;
  background: var(--n0);
  border-radius: .5rem;
  box-shadow: 0rem .5rem 1.25rem .25rem rgba(0, 0, 0, 0.1);

  &-head {
    display: flex;
    padding: .75rem 1.5rem;
    align-items: center;
    border-bottom: .0625rem solid var(--n40);

    .chart {
      margin-right: .75rem;
    }
    .checklist-summary {
      flex-grow:1;

      p {
        margin:0;
        font-size: .8125rem;
        line-height: 1.125rem;
        color: var(--n200);
      }
      h6 {
        font-size: .8125rem;
        font-weight: 500;
        line-height: 1.125rem;
        margin:0;
        text-transform: none;
      }
    }
    .arrow {
      padding-right: .75rem;
      font-size: .625rem;
      color: var(--n100);
      transform: rotate(180deg);
    }
    &.collapsed {
      padding: .625rem 1.5rem .625rem 1rem;

      .arrow {
        transform: none;
      }
    }
  }
  &-content {
    padding: 1rem 1.5rem 1.5rem 1.5rem;

    h4 {
      margin-bottom: .25rem;
      line-height: 1.5rem;
    }
    h6 {
      font-size: .8125rem;
      font-weight: 500;
      line-height: 1.125rem;
      margin:0;
      text-transform: none;
      color: var(--n200);
    }
    .timeline {
      .p-timeline {
        margin-top:1.5rem;
        margin-bottom: 1.75rem;

        &.p-timeline-vertical {
          .p-timeline-event {
            min-height:0;

            + .p-timeline-event {
              .innerDiv {
                margin-top: 1.5rem;
              }
            }
          }
          .p-timeline-event-separator {
            width: 1.6875rem;
            flex: 0 0 1.6875rem;
            
            .innerDiv {
              padding: .3125rem 0 0 .3125rem;
              height: 100%;
            }
            span {
              display: block;
              height: 1.375rem;
              width: 1.375rem;
              line-height: 1.375rem;
              border: .125rem solid var(--n40);
              border-radius: 50%;

              &.complete {
                border:0 none;
                background-color: var(--g300);
                text-align: center;
              }
              i {
                font-size: .75rem;
                line-height: 1.375rem;
                color: var(--n0);
                display: inline-block;
                vertical-align: top;

                &:before {
                  width: auto;
                  margin-left: -0.0625rem;
                }
              }
            }
          }
          .p-timeline-event-opposite,
          .p-timeline-event-connector {
            display: none;
          }
          .p-timeline-event-content {
            padding:0 0 0 .5625rem;

            a {
              font-size: 1rem;
              line-height: 1.5rem;
              font-weight: 500;
            }
            h5 {
              font-size: 1rem;
              line-height: 1.5rem;
              font-weight: 500;
              margin:0;
            }
            p {
              font-size: .8125rem;
              line-height: 1.125rem;
              color: var(--n200);
              margin:0;
            }
          }
        }
      }
      &.checkDone {
        .p-timeline {
          &.p-timeline-vertical {
            .p-timeline-event {
              align-items: center;

              +.p-timeline-event {
                .innerDiv {
                  margin-top:.75rem;
                }
              }
            }
            .p-timeline-event-separator {
              padding-top:0;
            }
          }
        }
      }
    }
  }
  &-footer {
    display: flex;
    justify-content: flex-end;

    a {
      color: var(--n200);
      font-weight: bold;
      font-size: .75rem;

      &:hover {
        color: var(--n200);
        text-decoration: underline;
      }
    }
  }
}
