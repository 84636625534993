.section {
  &-title {
    margin-bottom: 1.6875rem;

    @media (min-width: $width-sm) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .lead {
      font-size: 1rem;
      margin: 0 0 0.5rem 0;

      @media (min-width: $width-sm) {
        margin-bottom: 0;
      }
    }
    &-people {
      margin:0 0 1rem 0;
    
      @media (min-width: $width-sm) {
        margin-bottom: .25rem;
      }
    }
  }
  &-body {
    background: var(--n0);
  }
}