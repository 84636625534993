.sd-pagination {
  padding:0;
  margin-top: 1.5rem;

  @media (min-width: $width-sm) {
    justify-content: flex-end !important;
  }

  .p-paginator-pages {
    .p-paginator-page {
      width: 2rem;
      height: 2rem;
      min-width: 2rem;
      color: var(--n500);
      font-size: .75rem;
      line-height: .75rem;

      &:not(.p-highlight) {
        &:hover {
          background: var(--b400);
          color: var(--n0);
        }
      }
      &.p-highlight {
        background: var(--b400);
        color: var(--n0);
      }
    }
  }
  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    color: var(--n500);
    font-size: .75rem;
    line-height: .75rem;

    &:not(.p-disabled) {
      &:not(.p-highlight) {
        &:hover {
          background: var(--b400);
          color: var(--n0);
        }
      }
    }
  }
  .p-dropdown-panel {
    padding: 0;
  
    .p-menu-list {
      .p-menu-separator {
        margin: 0;
      }
      .red-text {
        .p-menuitem-text {
          color: var(--o200);
        }
      }
      .p-menuitem {
        a {
          color: var(--n500);
  
          &:hover {
            background: none;
  
            .p-menuitem-text {
              color: var(--b400);
            }
          }
        }
        p {
          margin: 0;
  
          font-size: 0.8125rem;
          line-height: 1.125rem;
          color: var(--n200);
        }
      }
    }
    .p-dropdown-items {
      .p-dropdown-item {
        padding: 0.3125rem 0.5rem;
        line-height: normal;
        text-align: left;
  
        &:not(.p-highlight) {
          &:not(.p-disabled) {
            &:hover {
              background: var(--b10);
            }
          }
        }
        &.p-highlight {
          color: var(--b400);
          background: var(--b10);
  
          span {
            color: var(--b400);
          }
        }
        &:hover {
          .p-menuitem-text {
            color: var(--b400);
          }
        }
        span {
          margin: 0;
          font-size: 0.8125rem;
          line-height: 1.125rem;
          color: var(--n500);
        }
      }
    }
  }
  
}