//Default color
$white: #fff;
$black: #000;

//Asset base path
$asset-base-path: '../../assets' !default;

//Font family
$Avenir: 'Avenir Next W01', sans-serif;
$AvenirRound: 'Avenir Next Rounded W01', sans-serif;
$Larsseit: 'Larsseit', sans-serif;

//Font weight
$light: 300;
$regular: 400;
$medium: 500;
$bold:700;

//Font style
$body-font-size: 16px;
$body-line-height: 1.5;

//Screen sizes for media queries
$width-xxl: 90rem; //1440px
$width-xl: 75rem; // 1200px
$width-lg: 62rem; // 992px
$max-width-md: 61.9375rem; // 991px
$width-md: 48rem; // 768px
$max-width-sm: 47.9375rem; //767px
$width-sm: 36rem; //576px
$width-xs: 30rem; // 480px
$max-width-xs: 29.9375rem; // 480px
$width-xxs: 22.5rem; //360px

$global-width: 88.125rem !default;

//Colors
:root {
  --n0: #ffffff;
  --n20: #F9F9F9;
  --n30: #F4F4F6;
  --n40: #EAEAEC;
  --n60: #D5D5D8;
  --n80: #C0C0C5;
  --n100: #ABABB1;
  --n200: #818089;
  --n400: #575662;
  --n500: #42414F;
  --n600: #2D2C3C;
  --n800: #121217;

  --b10: #F4F3FB;
  --b20: #EBEBF7;
  --b30: #E5E5F4;
  --b50: #D0D0EC;
  --b100: #A1A0D9;
  --b200: #A1A0D9;
  --b400: #6D6CE9;
  --b600: #4343B5;
  --b700: #2217AF;
  --b800: #160FAB;
  --b1000: #0A0E63;

  --g50: #DFF4EE;
  --g60: #AFE2D5;
  --g200: #5EC6AA;
  --g300: #1EBD92;
  --g400: #24A683;

  --lb50: #F0FAFD;
  --lb60: #A8CDE1;

  --o50: #FDF2F2;
  --o100: #EF8282;
  --o200: #EF6262;
  --o300: #CE6060;

  --p200: #6C64C9;
  --p400: #5257F6;

  --y50: #FFF8D9;
  --y60: #F6CE68;

  --light-grey: #D1D1D1;
  --grey: #616161;
  --dark-grey: #535569;
  --light-purple: #F8F4FF;
  --pale-purple: #9E9AAC;
  --print-grey: #B7B7B7;
  --light-grey-arrow: #A3A3A3;
  --light-grey-border: #EAEAEA;
  --titan-white: #F5F4FF;
  --selago: #F4F3FE;
  --charade: #2D2C3C;
}