// Client
.form__client {
  max-width:800px;
  margin:0 auto;
  display:block;

  @media (min-width: $width-sm) {
    position:relative;
    padding-right:160px;
  }
  input {
    font-size:14px;

    @media (min-width: $width-sm) {
      height:52px;
    }
  }
  .form__client--input {
    position:relative;

    @media (min-width: $width-sm) {
      padding-right:150px;
    }
  }
  .chosen_wrap {
    @media (min-width: $width-sm) {
      position:absolute;
      right:0;
      top:0;
      width:190px;
    }
    @media (min-width: $width-md) {
      width:250px;
    }
  }
  .chosen_item {
    margin-bottom:15px;

    .chosen-container {
      .chosen-single {
        box-shadow: 0px 1px 2px 0 rgba(0,0,0,0.16);
        height:44px;
        padding:10px 8px;
        font-weight:normal;
        background:var(--n0);

        @media (min-width: $width-sm) {
          height:52px;
          line-height:34px;
        }
        div {
          b {
            margin-top:10px;

            &:before {
              font-family: "torobravo";
              font-style: normal;
              font-weight: normal;
              speak: none;

              display: inline-block;
              text-decoration: inherit;
              width: 1em;
              margin-right: .2em;
              text-align: center;
              color:#A3A3A3;
              content: '\e806';
            }
          }
        }
      }
      .chosen-drop {
        min-width:160px;
      }
    }
  }
  .button {
    float:right;
    padding:10px 30px;
    font-size:14px;

    @media (min-width: $width-sm) {
      float:none;
      position:absolute;
      right:0;
      bottom:1rem;
      padding:12px 30px;
      font-size:18px;
    }
  }
  .form__client--btn {
    text-align:right;

    .button {
      float:none;
      margin-left:20px;
    }
  }
  &.form__setting {
    padding:0;

    .button {
      margin:auto;
      position:static;
    }
    .form__client--btn {
      text-align:left;
    }
  }
}
.client__list {
  margin:40px 0 0 0;
  list-style:none;
  font-size:0;
  max-width:1100px;
  text-align:center;

  @media (min-width: $width-sm) {
    padding:0 50px;
  }
  @media (min-width: $width-md) {
    margin-left:auto;
    margin-right:auto;
  }
  @media (min-width: $width-lg) {
    padding:0;
  }

  > li {
    padding:5px;
    display:inline-block;
    vertical-align:top;

    .dd {
      display:block;
      padding:10px 15px 10px 20px;
      border:1px solid #C9C9C9;
      border-radius:1000px;
      font-size:14px;
      font-weight:$bold;
      color:#3B3B3B;

      i {
        margin-left:15px;
        color:#ADADAD;
      }
      &:hover,
      &:active,
      &:focus,
      &.dd-open {
        color:#4934BF;

        i {
          color:#4934BF;
        }
      }
    }
    .dropdown-pane {
      top:100%;
      right:0;
      max-width:140px;

      @media (min-width: $width-sm) {
        top:0;
        right:40px;
      }
      @media (min-width: $width-md) {
        max-width:200px;
      }
    }
  }

  .pill_deleted {
    .dd {
      opacity: 0.3;
      transition: opacity .25s ease-in-out;
    }
  }
}
.modal__block {
  .form__client {
    max-width:none;
    position:static;
    padding:0;
  }
}
