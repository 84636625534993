/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 */

/* Custom SCSS */
@import "assets/scss/base";

.page-loading-bar {
   height: 5px;
   position: fixed !important;
   top: 0;
   left: 0;
   width: 100%;
   z-index:10001;
}