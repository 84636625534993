.badge {
  .p-badge {
    border-radius: .25rem;
    height: 1.125rem;
    padding: 0 .25rem;
    line-height: 1.125rem;
    font-size: .625rem;
    font-weight: $regular;
  }
  &-yellow {
    .p-badge {
      background:var(--y60);
      color: var(--n500);
    }
    
  }
  &-ghost {
    .p-badge {
      height:20px;
      border: .0625rem solid var(--n60);
      color:var(--n100);
      background:none;
    }
  }
  &.badge-lg {
    .p-badge {
      height: 1.25rem;
      line-height: 1.25rem;
      font-size: .875rem;
    }
  }
}