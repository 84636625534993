#Timer {
  background: var(--n0);
  box-shadow: 0 0 1.25rem 0.25rem rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  padding: 0.9375rem 0.9375rem 0 0.9375rem;
  margin-top: -4.0625rem;

  @media (min-width: $width-sm) {
    padding-bottom: 0.375rem;
  }
  @media (min-width: $width-lg) {
    padding: 1.5rem 2.25rem;
    position:relative;
  }
  @media (min-width: $width-xl) {
    padding: 1.875rem 2rem 2.25rem 2rem;
  }
  .form-input {
    border: 0 none;
    margin: 0;
    padding: 0;

    .create-input {
      position:relative;
      
      .p-inputtext {
        border: .0625rem solid var(--n60);
        padding: .4375rem 1.875rem .4375rem .75rem;
        border-radius: .25rem;
        font-size: .8125rem;
        line-height: normal;
        font-weight: $regular;
      }
      .btn-close {
        box-shadow: none;
        position:absolute;
        top:0;
        right:0;
        border:0 none;
        background: transparent;

        &:hover {
          background: transparent;
        }
      }
    }
    .p-field {
      width: 100%;
      margin-bottom: 0.625rem;

      @media (min-width: $width-lg) {
        margin-bottom: 1.5625rem;
      }
      @media (min-width: $width-xl) {
        margin-bottom: 0;
      }
      &-inner {
        @media (min-width: $width-lg) {
          padding: 0.375rem 0.75rem;
          border-radius: 0.25rem;
        }
        &:hover,
        &:active,
        &:focus {
          background: var(--n20);
        }
      }
      &.timer-entry {
        @media (min-width: $width-lg) {
          width: 44%;
          flex-shrink: 0;
          flex-grow: 0;
          margin-bottom: 0;
        }
        @media (min-width: $width-xl) {
          width: auto;
          flex: 1;
        }
        .p-autocomplete-panel {
          .p-autocomplete-items {
            padding: 1.25rem 1rem;

            .p-autocomplete-item {
              padding: 0.5rem 0.5rem 0.8125rem 0.5rem;
              border-radius: 0.25rem;
              margin-bottom: 0.0625rem;

              h5 {
                font-weight: $medium;
              }
              p {
                margin: 0;
                font-size: 0.6875rem;

                span {
                  color: var(--n100);
                }
              }
              &.p-highlight,
              &:hover {
                background: var(--b10);

                h5 {
                  color: var(--b400);
                }
              }
            }
            &:before {
              content: "Done this task before? Click to resume.";
              display: block;
              font-size: 0.8125rem;
              line-height: 1.125rem;
              font-weight: $medium;
              color: var(--n200);
              margin-bottom: 0.875rem;
            }
          }
        }
      }
      &.timer-task,
      &.timer-project {
        @media (min-width: $width-sm) {
          width: 40%;
        }
        @media (min-width: $width-md) {
          width: 30%;
        }
        @media (min-width: $width-lg) {
          width: 24%;
          flex-shrink: 0;
          flex-grow: 0;
        }
        @media (min-width: $width-xl) {
          width: 17%;
        }
      }
      &.timer-option-bill {
        width: auto;
        text-align: center;
        padding-right: 1.875rem;

        @media (min-width: $width-md) {
          flex: 1;
          text-align: right;
        }
        @media (min-width: $width-lg) {
          padding: 0;
          flex-shrink: 0;
          text-align: center;
        }
        @media (min-width: $width-xl) {
          padding: 0 0.9375rem 0 0;
          width: auto;
          flex: 0;
        }
        .p-field-inner {
          @media (min-width: $width-md) {
            display:inline-block;
            text-align: center;
          }
        }
        label {
          display: block;
        }
        .p-button {
          background: none;
          @media (min-width: $width-lg) {
            margin-right: 0.3125rem;
          }
          @media (min-width: $width-xl) {
            margin-right: 0;
          }
        }
      }
      &.timer-button {
        width: auto;
        margin-left: auto;

        @media (min-width: $width-lg) {
          margin-bottom: 0;
        }
        .p-d-inline-flex {
          width: 100%;
        }
        .timer-duration {
          padding-top: 0.3125rem;
          margin-right: 1.0625rem;

          @media (min-width: $width-sm) {
            width: auto;
            text-align: right;
          }
          @media (min-width: $width-lg) {
            min-width: 7.5rem;
          }
          p {
            margin: 0;
            font-size: 1.75rem;
          }
        }
        .btn-sd {
          min-width: 5.9375rem;
          text-align: center;
          opacity:1 !important;
        }
        .timer-start {
          color: var(--g300);
          font-weight: $medium;
          font-size: .8125rem;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
        p {
          margin-bottom: 0;

          @media (min-width: $width-xl) {
            margin-bottom: -2.25rem;
          }
        }
      }
      &.timer-status {
        width: auto;

        @media (min-width: $width-sm) {
          padding-right: 0.9375rem;
        }
        @media (min-width: $width-lg) {
          margin-bottom: 0;
        }
        .p-inputtext {
          font-size: 0.8125rem;
          color: var(--n500);
          height: 1.5rem;
          margin-top: 0.125rem;
          border-radius: 0;
          width: 8ch;
          background: none;
        }
        .p-calendar {
          vertical-align: middle;
          margin-right: 0.3125rem;
          line-height: 0.8125rem;

          .p-inputtext {
            order: 1;
            font-size: 0.8125rem;
            font-weight: $medium;
            color: var(--g300);
            border: 0 none;
            border-radius: 0;
            height: 1.5rem;
            width: 6ch;
            margin-top: 0;
          }
          .p-datepicker-trigger {
            order: 0;
            background: none;
            padding: 0;
            color: var(--g300);
            border: 0 none;
            border-radius: 0;
            width: auto;
            margin-right: 0.15625rem;
            font-size: 0.75rem;
          }
        }
        i {
          color: var(--n100);
          margin: 0 0.5rem 0 0.1875rem;
          font-size: 0.8125rem;

          &:before {
            width: auto;
          }
        }
        &.p-d-block {
          + .timer-button {
            @media (min-width: $width-sm) {
              width: 100%;
            }
            @media (min-width: $width-lg) {
              width: auto;
            }
          }
        }
      }
    }
    .p-autocomplete {
      width: 100%;
    }
    .p-inputtext {
      border: 0 none;
      padding: 0;
      font-size: 1.5rem;
      font-weight: $regular;
      height: auto;
    }
    .last-task {
      font-size: .8125rem;
      color: var(--n200);
      margin-top: 0.9375rem;
      margin-bottom: 0;
      line-height: 1.125rem;

      @media (min-width: $width-lg) {
        margin-bottom: -2.25rem;
      }
      a {
        color: var(--g300);
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 700;
        display: inline-block;
        margin-left: 0.1875rem;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    .ng-select {
      .ng-arrow-wrapper {
        padding-right: 0;

        &:before {
          font-family: "torobravo";
          font-style: normal;
          font-weight: normal;
          speak: none;

          display: inline-block;
          text-decoration: inherit;
          width: 1em;
          margin-right: 0.2em;
          text-align: center;
          color: var(--n100);
          content: "\e816";
        }
        .ng-arrow {
          display: none;
        }
      }
      .ng-select-container {
        border: 0 none;
        height: 1.5625rem;
        min-height: 1.5625rem;
        background: none;

        &:hover {
          box-shadow: none;
        }
        .ng-value-container {
          padding-left: 0;
          height: 100%;

          .ng-value {
            height: 1.5625rem;
          }
          .ng-value-label {
            font-size: 0.8125rem;
            color: var(--n100);
            font-weight: $medium;
            line-height: 0.8125rem;
          }
        }
        .ng-input {
          padding-left: 0;
          top: 0;
          height: 1.5625rem;

          input {
            height: 1.5625rem;
          }
        }
      }
    }
    label {
      font-size: 0.625rem;
      line-height: 0.875rem;
      font-weight: $medium;
      color: var(--n100);
      margin-bottom: 0;
      display: block;
    }
  }
}
