#Header {
  margin-bottom: 1.25rem;
  background: #4934BF url("/assets/images/art/header.png") center center no-repeat;
  background-size: cover;

  @media (min-width: $width-lg) {
    margin-bottom: 2.1875rem;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.75rem;

    @media (min-width: $width-lg) {
      height: 5rem;
      justify-content: left;
    }
  }
  .title-bar {
    background: none;
    padding: 0;

    @media (min-width: $width-lg) {
      display: none;
    }
    .menu-icon {
      background: none;
      border: 0 none;
      color: var(--n0);
      cursor: pointer;
      padding: 0.5rem;
    }
  }
  + .sd_container {
    padding-top: 2.1875rem;

    &.section-top-history {
      padding-top: 0;
    }
  }
  // bell notification
  .notif {
    position: relative;

    @media (min-width: $width-lg) {
      margin-right: 13.75rem;
      top: 1.875rem;
      height: 1.3125rem;
      width: 1.375rem;
    }
    @media (min-width: $width-lg) {
      margin-right: 13.75rem;
    }
    .btn-notif {
      position: relative;
      i {
        color: var(--n0);
        font-size: 1.125rem;
      }
    }
    &.default-notif {
      .dropdown-pane {
        &.dropdown-pane-parent {
          max-width: 17.5rem;

          @media (min-width: $width-sm) {
            max-width: 19.375rem;
          }
          .dropdown_content--default {
            display: block;
          }
        }

        .notif-content {
          display: none;
        }
      }
    }
    .dropdown-pane {
      top: 1.875rem;

      &.dropdown-pane-parent {
        right: -1.5rem;
        width: 17.5rem;
        max-width: 17.5rem;

        @media (min-width: $width-sm) {
          width: 27.4375rem;
          max-width: 27.4375rem;
        }
      }
      .dropdown_content--default {
        padding: 2.5rem 1.875rem;
        text-align: center;
        display: none;

        img {
          width: 9.4375rem;
          height: auto;
          display: block;
          margin: 0 auto;
        }
        p {
          margin-top: 1.25rem;
          font-size: 1.125rem;
          color: var(--grey);
          margin-bottom: 0;
          font-weight: $bold;

          small {
            display: block;
            font-size: 0.875rem;
            margin-top: 0.25rem;
            font-weight: normal;
          }
        }
      }
      .notif-content {
        .dropdown-head {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 0.75rem 1.875rem;
          border-bottom: 0.0625rem solid var(--light-grey)-border;

          h6 {
            font-size: 0.875rem;
            font-weight: $bold;
            font-family: $Larsseit;
            color: var(--n600);
            margin-bottom: 0;
            padding-right: 0.625rem;
          }
          a {
            color: var(--g300);
            font-size: 0.875rem;

            &:hover,
            &:focus {
              text-decoration: underline;
            }
          }
        }
        > ul {
          max-height: 18.75rem;
          overflow-y: auto;

          @media (min-width: $width-md) {
            max-height: 28.125rem;
          }
          > li {
            position: relative;

            &:hover {
              .notif-dismiss {
                display: block;
              }
            }
            > a {
              padding: 1.25rem 1.875rem 0.9375rem 2.625rem;

              &:hover,
              &:focus {
                background: var(--light-purple);
              }
              h6 {
                font-weight: $bold;
                font-size: 0.875rem;
                color: var(--n600);
              }
              p {
                font-size: 0.875rem;
                font-weight: normal;
                margin-bottom: 0.625rem;
                color: var(--grey);
              }
              small {
                display: block;
                font-size: 0.75rem;
                font-weight: normal;
                color: #b4b4b4;
              }
              &.notif-dismiss {
                position: absolute;
                right: 0.5rem;
                top: 1.125rem;
                font-size: 1rem;
                padding: 0;
                color: var(--print-grey);

                @media (min-width: $width-md) {
                  display: none;
                }
                &:hover {
                  background: none;
                }
              }
            }
            &.new {
              &:before {
                position: absolute;
                top: 1.625rem;
                left: 1.8125rem;
                width: 0.3125rem;
                height: 0.3125rem;
                line-height: 100%;
                border-radius: 50%;
                background: var(--o200);
                display: block;
                content: "";
              }
            }
          }
        }
      }
    }
    &.has-notif {
      .btn-notif {
        &:after {
          position: absolute;
          top: -0.3125rem;
          right: 0;
          width: 0.75rem;
          height: 0.75rem;
          line-height: 100%;
          border: 0.125rem solid #5852ca;
          border-radius: 50%;
          background: var(--o200);
          display: block;
          content: "";

          @media (max-width: $width-md) {
            border: 0.125rem solid #4e40c4;
            right: 0.25rem;
          }
        }
      }
      .dropdown-pane {
        .dropdown_content--default {
          display: none;
        }
      }
    }
  }
}
#Logo {
  @media (min-width: $width-lg) {
    margin-top: -0.3125rem;
  }
  a {
    font-family: $Larsseit;
    text-rendering: optimizeLegibility;
    font-weight: bold;
    font-size: 1rem;
    color: var(--n0);
    display: block;

    @media (min-width: $width-lg) {
      font-size: 1.5rem;
      margin-right: 1rem;
    }
    img {
      width: auto;
      height: 1.75rem;
      margin-right: 0.75rem;

      @media (min-width: $width-lg) {
        height: 2rem;
      }
    }
  }
}
#Header {
  &.page-timer {
    padding-bottom: 4.0625rem;
    margin-bottom: 0;
  }
}