.recent-card {
  .p-card-body {
    &:hover {
      .today-duration {
        .btn-dd {
          @media (min-width: 48rem) {
            display: block;
          }
        }
      }
    }
  }
}
.menu-wrapper {
	float: right;
  direction: rtl;

	.btn-dd {
		color: var(--n500);
	}
}
.report {
	&-title {
		margin-bottom: 1rem;
	}
	&-head {
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 0.75rem;

		h4 {
			font-size: 1.25rem;
			line-height: 1.4375rem;
			font-weight: $medium;
		}
		p {
			margin-bottom: 0;
		}
		.today-duration {
			order: 1;

			@media (min-width: $width-md) {
				order: 20;
			}
			.untracked {
				color: var(--n100);
        min-width: 4.375rem;
        text-align: right;

				&.button {
					color: var(--g300) !important;
				}
			}
			p {
				&:not(.untracked) {
					display: inline-block;
					margin: 0 1.5rem 0 0;
					font-size: 1.25rem;
					line-height: 1.5rem;
					font-weight: $medium;
					vertical-align: middle;
				}
			}
			.btn-icon {
				padding: 0;
				margin: 0;
				width: auto;
				color: var(--n100);
				vertical-align: middle;

        .p-button-label {
          display: none;
        }

				&:hover {
					color: var(--g300);
				}
			}
      .button-wrapper {
        min-width: 1rem;
        display: inline-block;
      }
      .btn-dd {
        @media (min-width: 48rem) {
          display: none;
        }
      }
      &.expended {
        .btn-dd {
          @media (min-width: 48rem) {
            display: block;
          }
        }
      }
		}
		.today-insight {
			order: 2;
			flex: 1 1 100%;

			@media (min-width: $width-md) {
				order: 1;
				flex: 1 1 auto;
				text-align: left;
				padding-left: 1rem;
			}
			p {
				display: inline-block;
				vertical-align: middle;
				margin: 0;

				img {
					display: inline-block;
					vertical-align: middle;
					margin-right: 0.1875rem;
				}
				span {
					background: var(--y50);
					font-size: 0.8125rem;
				}
			}
		}
		p-togglebutton {
			margin-left: 1rem;

			.toggler {
				font-size: 0.5rem;
				color: var(--n500);
				width: 1.25rem;
				padding: 0;
				vertical-align: middle !important;

				span {
					color: var(--n100) !important;
				}
				&.p-highlight {
					span {
						color: var(--n500) !important;
					}
				}
				span {
					&:before {
						width: auto;
					}
				}
			}
		}
	}
	&-tracked {
		width: 100%;
    min-height: 4.375rem;

		.report-avatar {
			width: 2.5rem;
			height: 2.5rem;
			margin-top: 1rem;
			margin-right: 1rem;
		}
    .report-charts {
      flex: 1 0 100%;
      width: 100%;
      position:relative;

      &:before {
        display: block;
        content:'';
        background: var(--n20);
        position: absolute;
        height: 1.1875rem;
        left:0;
        right:0;
        top: 50%;
        transform: translateY(-50%);
      }
      &.report-today-empty {
        .highcharts-background {
          fill: none;
        }
        &:before {
          height: 1.375rem;
          top: .8125rem;
          transform: none;

          @media (min-width: 30rem) {
            height: 1.875rem;
            top: 1.125rem;
            max-width: 60.625rem;
          }
        }
      }
      &.report-today-fill {
        &:before {
          display: none;
        }
      }
    }
		.chart-container {
			min-width: 12.5rem;
			max-width: 60.625rem;
			display: block;
		}
	}
	&-content {
		margin-bottom: 2rem;

		&.today-progress {
			h2 {
				font-weight: $regular;
				margin-bottom: 8px;
			}
			.report-charts {
				flex: 1 0 auto;
				width: calc(100% - 3rem);
			}
			.report-tracked {
				max-width: 65.1875rem;
				margin: 0 auto;
			}
			.today-duration {
				@media (min-width: $width-md) {
					padding-right: 2.1875rem;
				}
			}
		}
		&.timer-list {
			.table-entry-wrapper {
				display: none;

				&.shown {
					display: block;
				}
			}
			.report-avatar {
				display: none;
			}
			.report-head {
				h4 {
					margin: 0;
				}
			}
			.p-card-body {
				padding: 1.5rem;

				&:hover {
					.toggler span {
						color: var(--g300) !important;
					}
				}
			}
			.p-calendar {
				.p-inputtext {
					width: 3.125rem;
					border-radius: 0;
				}
			}
			.chart-container {
				min-width: 12.5rem;
				max-width: 56.875rem;
				position: relative;

				.highcharts-container {
					margin: 0 auto;
				}
			}
			.listingonly-view {
				.table-entry-wrapper {
					display: block;
				}
				.report-head {
					margin-bottom: 0.375rem;

					.today-duration {
						p-togglebutton {
							display: none;
						}
					}
				}
				.report-tracked {
					display: none !important;
				}
			}

			@media (min-width: 48rem) {
				.p-card-body {
					padding: 1.5rem 1rem;
					position: relative;
				}
				.report-head {
					margin-bottom: 0;

					.untracked {
						color: var(--n100);
					}
				}
				.report-tracked {
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
					width: 40%;
				}
			}
			@media (min-width: 62rem) {
        .p-card-body {
          padding-left: 2rem;
          padding-right: 2rem;
        }
				.report-tracked {
					width: 50%;
				}
			}
			@media (min-width: $width-xxl) {
				.report-tracked {
					padding-left: 0;
					padding-right: 0;
					width: 56.875rem;
				}
			}
		}
	}
	&-subtitle {
		width: 100%;
		margin-bottom: 1rem;

		h5 {
			margin-bottom: 0;
		}
	}
}
.offday {
	.p-card {
		background: var(--n30);

    .report-head {
      flex-wrap: nowrap;
      margin:0;

      h4 {
        white-space: nowrap;
        padding-right: .625rem;
      }
      .today-duration {
        min-width: 8.1875rem;
        
        p {
          @media (min-width: 48rem) {
            text-align: left;
          }
        }
      }
    }
    .report-tracked {
      text-align: center;
      min-height: 0;
      padding: 1rem 0 0 0;

      @media (min-width: 48rem) {
        min-height: 100%;
        padding:0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      p {
        font-size: 1rem;
        margin:0;
      }
    }
		h4,
		p {
			color: var(--n100);
		}
	}
}
