.filter-tags {
	margin-bottom: 1.25rem;

	h6 {
		margin: 0 0.75rem 0 0;
		font-weight: $bold;
		letter-spacing: 0.0625rem;
		color: var(--n200);
	}
	.sd-tag {
		margin-left: 0.5rem;
	}
}

.time-filter-wrapper {
	text-align: center;

	.time-filter {
		display: flex;
		align-items: center;

		&:before,
		&:after {
			content: "";
			width: 100%;
			height: 0.0625rem;
			background: var(--n60);
		}
		&:before {
			margin: 0 1.5rem 0 0;
		}
		&:after {
			margin: 0 0 0 1.5rem;
		}
		.time-range-label {
			flex: 1 0 auto;

			label {
				margin-bottom: 0;
				color: var(--n200);
				font-weight: $regular;
				font-size: 1.5rem;
				line-height: 1.8125rem;
				font-feature-settings: "ss01" on, "ss02" on;
			}
		}
		p-button {
			&:first-child {
				margin-right: 1.5rem;
			}
			&:last-child {
				margin-left: 1.5rem;
			}
      .p-button {
        width: 2rem;
        height: 2rem;
        border: 0 none;

        &:enabled {
          &:hover {
            box-shadow: 0rem .5rem 1.25rem .25rem rgba(0, 0, 0, 0.1);
            border: 0 none;

            .p-button-icon {
              color: var(--b400);
            }
          }
        }
        &:disabled {
          background: var(--n40);
          box-shadow: 0rem .0625rem .125rem rgba(0, 0, 0, 0.2);
        }
      }
    }
	}
}
