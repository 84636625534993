.sd-chips {
  width: 100%;

  .p-chips-multiple-container {
    width: 100%;

    .p-chips-token {
      padding-top: 0;
      padding-bottom: 0;
    }
    .p-chips-input-token {
      padding: 0;

      input {
        height: 2rem;
        font-family: $Larsseit;
      }
    }
  }
}
