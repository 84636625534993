
#Login {
  .bg {
    height: 36.25rem;
    position:absolute;
    top:-170px;
    left:0;
    right:0;
    z-index:1;

    @media (min-width: $width-md) {
      top:0;
    }

    img {
      max-width:none;
      width:100%;
    }
  }
}
.bg-grey {
  background: var(--n20) !important;
}