.sd-dialog {
	&.dialog-onboarding {
		max-height: none;
		border-radius: .5rem;
		max-width: 100%;
    max-height: 100%;
    height: 100%;

		@media (min-width: $width-md) {
			max-width: 28.75rem;
			min-height: 42.5rem;
      height: auto;
      overflow: hidden;
		}
    .p-dialog-content {
      height: 100%;
      border-radius: 0;
      overflow: hidden;

      .swiper-container {
        height: 100%;
      }
    }
    .welcome,
    .last {
      .swiper-pagination {
        display: none;
      }
    }
    .modal-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      min-height: 42.5rem;
    }
    .step1 {
      position: relative;

      .img-block {
        padding: .9375rem;

        .wave {
          position: absolute;
          left:50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(-30deg);
          z-index: 1;
          width: 9.375rem;

          @media (min-width: $width-md) {
            width: 12.5rem;
          }
        }
      }
      .swiper-pagination {
        display: none;
      }
      .modal-text {
        position: relative;
        
        img {
          position: absolute;
          bottom:0;
          left:0;
        }
      }
      .modal-content {
        padding-top: 3.5rem;
        position: relative;
        z-index: 2;
      }
    }
    .step2 {
      .img-block {
        .img {
          width: 100%;
          position: absolute;
          top: 2.5rem;
          z-index: 3;
        }
      }
    }
    .step3 {
      .img-block {
        .img {
          width: 100%;
          position: absolute;
          top: 1.125rem;
          z-index: 3;
        }
      }
    }
    .step4,
    .step-calendar {
      .img-block {
        .img {
          width: 100%;
          position: absolute;
          top: 1.5625rem;
          z-index: 3;

          @media (min-width: $width-md) {
            top: 3.125rem;
          }
        }
      }
    }
    .step-calendar {
      .modal-content {
        p {
          + p {
            margin-bottom: 2rem;
          }
        }
      }
    }
    .step5 {
      .img-block {
        .img {
          width: 100%;
          position: absolute;
          top: 1rem;
          z-index: 3;
        }
      }
      .modal-content {
        padding-top: 5.625rem;

        p {
          margin-bottom: 2.5rem;
        }
      }
    }
    .step-project {
      min-height: 45.625rem;

      .img-block {
        .img {
          width: 100%;
          position: absolute;
          top: 3.5rem;
          z-index: 3;
          max-width: 27.3125rem;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .modal-content {
        padding-top: 6.25rem;

        @media (min-width: $width-md) {
          padding-top: 5.5rem;
        }
        p {
          margin-bottom: 2rem;

          &:last-child {
            margin-bottom:0;
          }
        }
        .btn-sd {
          margin-bottom: 1rem;
        }
      }
    }
    .step-dock {
      .img-block {
        .img {
          left: .25rem;
          width: 100%;
          position: absolute;
          top: 2.5rem;
          z-index: 3;

          @media (min-width: $width-md) {
            top: 3.125rem;
          }
        }
      }
      .modal-content {
        p {
          span {
            font-size: 1rem;

            &:before {
              margin:0 .125rem;
              width: auto;
            }
          }
        }
      }
    }
    .img-block {
      position: relative;

      .bg {
        position: relative;
        z-index: 2;
      }
      .btn-sd {
        &.btn-close {
          z-index: 4;
          display: block;
          box-shadow: none;
          position: absolute;
          right:.75rem;
          top:.75rem;
          color: var(--n600);
          width: 1.5rem;
          border:0 none;
          padding:0;
          height: 1.5rem;
          line-height: 1.5rem;
          background: none;

          @media (min-width: $width-md) {
            right: 1.5rem;
            top: 1.5rem;
          }
          span {
            &:before {
              line-height: 1.5rem;
            }
          }
        }
      }
      img {
        object-fit: contain;
      } 
    }
		.swiper-pagination {
			display: inline-flex;
			margin: 0;
			padding: 0;
			list-style: none;
			justify-content: center;
      position: absolute;
      bottom: 6.5rem;

			.swiper-pagination-bullet {
				margin: 0 0.125rem;
				width: 0.5rem;
				height: 0.5rem;
				border-radius: 50%;
				background: var(--n40);
        opacity: 1;

				&-active {
					background: var(--g200);
				}
        &:first-child {
          display: none;
        }
			}
		}
  
		.modal-text {
			border-radius: 0.5rem 0.5rem 0 0;
			background: $white;
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			flex:1;
			text-align: center;
			margin-top: -0.5rem;
		}
    .modal-content {
      flex: 1;
      padding: 2.5rem 1.5rem 1.5rem 1.5rem;

      @media (min-width: $width-md) {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
      }
      h3 {
        font-size: 2rem;
        line-height: 2.375rem;
        margin-bottom: 1rem;
      }
      p {
        margin-bottom: 1.5rem;
        color: var(--n500);
        font-size: 1.25rem;
        line-height: 1.75rem;

        small {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
    }
    .modal-footer {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem;
      border-top: .0625rem solid var(--n40);

      @media (min-width: $width-md) {
        padding: 1.5rem 2.5rem;
      }
      .btn-prev {
        padding-left:0;
        padding-right:0;
        border:0 none;
      }
    }
	}
}
