.sd-form {
  .p-field {
    label {
      display: block;
    }
    .sd-upload {
      text-align: center;

      .p-fileupload-choose {
        &:hover,
        &:active,
        &:focus {
          background-color: var(--g400);
          color: var(--n0);
        }
        &:enabled {
          &:hover,
          &:active,
          &:focus {
            background-color: var(--g400);
            color: var(--n0);
          }
        }
        &:disabled {
          background-color: var(--g60);
        }
        .p-button-icon {
          display: none;
        }
      }
    }
  }
  .remove-link {
    text-align: center;

    a {
      font-weight: $bold;
      color: var(--g300);
    }
  }
  .img-preview {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    margin: 0 auto 1rem auto;
  }
}
.form-dialog {
  .form-container {
    padding: 0 1.25rem;

    @media (min-width: $width-sm) {
      padding: 0 2.5rem;
      margin-bottom: 3.25rem;
      display: flex;
    }
    .form-input {
      padding: 0;
      margin: 0;

      &.left {
        @media (min-width: $width-sm) {
          order: 0;
          flex: 0 0 280px;
        }
      }
      &.right {
        @media (min-width: $width-sm) {
          order: 1;
          margin-left: 4rem;
        }
      }
    }
  }
  label {
    margin-bottom: 0;
  }
  .form-input {
    border: 0 none;
    padding: 0 1.25rem;
    margin-bottom: 1.625rem;

    @media (min-width: $width-sm) {
      padding: 0 2.5rem;
      margin-bottom: 3.25rem;
    }
    p-chips {
      display: block;
    }
    h5 {
      margin-bottom: 1rem;
    }
    .profile-avatar {
      width: 8rem;
      height: 8rem;
      margin-bottom: 1rem;
      background: var(--y60);

      .p-avatar-text {
        font-size: 2.5rem;
        font-weight: $bold;
        color: var(--n500);
      }
    }
    .p-field {
      &:last-child {
        margin-bottom: 0;
      }
      > label {
        display: block;
        font-size: 1rem;
      }
      [type="text"],
      .p-inputtext {
        font-size: 0.8125rem;
        font-family: $Larsseit;

        @media (min-width: $width-sm) {
          font-size: 1rem;
        }
      }
      .selectbox {
        display: block;

        .p-dropdown {
          max-width: 100%;
        }
      }
      .p-dropdown {
        width: 100%;
        max-width: 16.875rem;
        color: var(--n500);
        height: 3rem;

        .p-dropdown-label {
          padding: 0.75rem 1rem;
        }
        &.fw {
          max-width: none;
        }
      }
      .helper {
        margin: 0.25rem 0 0 0;
        font-size: 0.8125rem;
        line-height: 1.125rem;
        color: var(--n200);
      }
      .box {
        margin-top: 1.25rem;

        .p-field-checkbox {
          margin-bottom: 0;
        }
      }
      .sd-select {
        .ng-select-container {
          font-weight: normal;
          border: 1px solid var(--n60);
        }
      }
    }
  }
  .switch {
    padding-top: 0.375rem;

    &-text {
      margin-right: 2.6875rem;

      label {
        margin-bottom: 0.4375rem;
        font-size: 1rem;
      }
      p {
        margin: 0;
      }
    }
    &-container {
      margin-top: 1.875rem;
    }
  }
  .form-footer {
    border: 0 none;
    border-top: 0.0625rem solid var(--n40);
    text-align: right;
    padding: 1.625rem 1.25rem;

    @media (min-width: $width-sm) {
      padding: 1.5rem 2.5rem;
    }
    &.text-right {
      text-align: right;

      .btn-sd {
        &:not(.btn-ghost) {
          margin-left: 0.5rem;
        }
      }
    }
  }
}
.form-filter {
  margin-left: -1rem;
  margin-right: -1rem;
  border-bottom: 0.0625rem solid var(--n40);
  margin-bottom: 2.25rem;
  padding-left: 1rem;
  padding-right: 1rem;

  @media (min-width: $width-lg) {
    margin-left: -1.5625rem;
    margin-right: -1.5625rem;
    margin-bottom: 0.75rem;
  }

  fieldset {
    padding: 0;
    border: 0 none;
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.75rem;
    margin-right: -0.75rem;

    @media (min-width: $width-lg) {
      display: inline-flex;
      width: 100%;
      margin: 0;
      align-items: flex-start;
    }
    .p-field {
      width: 50%;
      padding: 0 0.75rem;
      order: 1;

      @media (min-width: $width-lg) {
        width: auto;
        flex: 1 1 auto;
        padding: 0 0.5625rem;
        margin-bottom: 0;

        &.sw {
          width: 10%;
        }
        &.bill {
          flex: 0 0 auto;
          width: 5rem;
        }
      }
      @media (min-width: $width-xl) {
        &.bill {
          width: 7.5rem;
        }
      }
      label {
        font-size: 0.625rem;
        line-height: 0.875rem;
        color: var(--n100);
        display: block;
        margin-bottom: 0;
      }
      .btn-dd {
        padding: 0;
        width: 100%;
        text-align: left;
        border: 0 none;

        p-button {
          line-height: 1rem;
          display: block;
        }
        .p-button-icon {
          font-size: 0.625rem;
        }
        .p-multiselect-label,
        .p-dropdown-label {
          padding: 0;
          color: var(--n500);
          font-size: 1rem;
          line-height: 1rem;
          font-weight: $medium;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .p-multiselect-trigger-icon,
        .p-dropdown-trigger-icon {
          font-size: 0.625rem;
          width: auto;
        }
        .p-multiselect-trigger,
        .p-dropdown-trigger {
          width: auto;
        }
      }
      &.filter-search {
        order: 0;
        width: 100%;

        @media (min-width: $width-lg) {
          order: 1;
          width: auto;
          max-width: 24.6875rem;
        }
        @media (min-width: $width-xl) {
          width: 24.6875rem;
        }
        .p-inputtext {
          height: 2.5rem;
          padding: 0.5rem 0.75rem;
        }
      }
      &.filter-button {
        flex: 1 1 auto;
        text-align: right;
        margin-bottom: .5rem;

        @media (min-width: $width-lg) {
          flex-grow: 0;
          text-align: center;
        }
        p-button {
          display: block;
          margin-bottom: .5rem;
        }
        a {
          font-size: .8125rem;
          color: var(--g300);
          font-weight: $medium;
          cursor: pointer;
        }
      }
    }
  }
}
.p-multiselect-panel {
  &.sd-dd {
    max-width: 15.625rem;

    .p-multiselect-filter-container {
      .p-multiselect-filter {
        height: 2.5rem;
        padding: 0.5rem 0.75rem;
      }
    }
    .p-multiselect-item {
      font-size: 0.8125rem;
    }
  }
}
.radio-list {
  list-style: none;
  margin:0;
  padding:0;
  
  li {
    &:not(:last-child) {
      margin-bottom: .5rem;
    }
  }
}
.sd-radio {
  .p-radiobutton {
    .p-radiobutton-box {
      &:not(.p-highlight) {
        &:hover {
          border-color: var(--g300);
        }
      }
      &:not(.p-disabled) {
        &.p-focus {
          box-shadow: 0 0 0 0.2rem var(--g60);
        }
      }
      &.p-highlight {
        border-color: var(--g300);
        background: none;

        .p-radiobutton-icon {
          background: var(--g300);
        }
        &:not(.p-disabled) {
          &:hover {
            border-color: var(--g300);
            background: none;
          }
        }
      }
    }
  }
  .p-radiobutton-label {
    font-weight: $regular;
    font-size: 1rem;
    color: var(--n400);
    line-height: 1.5rem;
  }
}
.p-field-checkbox,
.p-field-radiobutton {
  > label {
    margin-bottom:0;
  }
}