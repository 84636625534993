.sd-tooltip {
	&.p-tooltip-top {
		.p-tooltip-arrow {
			border-top-color: var(--n600);
		}
	}
	.p-tooltip-text {
		background: var(--n600);
		padding: 0.5rem 0.75rem;
		font-size: 0.8125rem;
		color: var(--n0);
		border-radius: 0.25rem;
	}
}
.highcharts-tooltip-container {
	z-index: 11 !important;
	margin-left: 120px;
	margin-top: 60px;
	transform: translate(-120px, -60px);

	* {
		font-family: "Larsseit", sans-serif !important;
	}
}
.calendar-tooltip {
	> span {
		background: var(--n0);
	}
	.panel-calendar {
		border-top: 0.3125rem solid #efeff9;
		width: 14rem;
	}
	ul {
		margin: 0;
		list-style: none;
		padding: 0 0.75rem;

		li {
			padding: 0.75rem 0;

			+ li {
				border-top: 0.0625rem solid var(--n40);
			}
			.panel-name {
				margin-bottom: 0.5rem;
				display: flex;
				flex-direction: row;
				align-content: center;

				img {
					flex: 0 0 1rem;
					width: 1rem;
					height: 1rem;
				}
				h5 {
					padding-left: 0.5rem;
					padding-right: 0.5rem;
					font-size: 0.8125rem;
					line-height: 1.125rem;
					margin: 0;
					white-space: normal;
				}
				.panel-status {
					margin-left: auto;

					a {
						font-size: 0.6875rem;
						line-height: normal;
						font-weight: 700;
						color: var(--g300);
						cursor: pointer;

						&:hover {
							color: var(--g200);
						}
					}
					span {
						display: inline-block;
						height: 1rem;
						line-height: 1rem;
						padding: 0 0.375rem;
						color: var(--n0);
						font-size: 0.625rem;
						font-weight: 700;
						border-radius: 0.25rem;
						background: var(--b600);
					}
				}
			}
			.panel-time {
				h6 {
					font-size: 11px;
					font-weight: 400;
					color: var(--n200);
					margin-bottom: 0.25rem;
					text-transform: none;
				}
				p {
					margin: 0;
					font-size: 0.6875rem;
					line-height: 0.6875rem;
					position: relative;
					padding-right: 3.125rem;

					i {
						font-size: 0.5rem;
						color: var(--n100);
						vertical-align: top;
						margin-right: 0.3125rem;
						margin-left: 0.125rem;
					}
					span {
						color: var(--n200);
						position: absolute;
						right: 0;
						top: 0;
					}
				}
			}
		}
	}
	.panel-footer {
		background: var(--n20);
		padding: 0.75rem;

		p {
			margin: 0;
			white-space: normal;
			font-size: 0.6875rem;
			line-height: 0.9625rem;
			color: var(--n200);
		}
	}
}
.focusTips {
	max-width: 21.25rem;

	.p-tooltip-arrow {
		display: none;
	}
  .p-tooltip-text {
    box-shadow: 0.625rem 0.625rem 1rem rgba(0, 0, 0, 0.1);
  }
	&.p-tooltip-top {
		margin-top: -0.625rem;

		.p-tooltip-text {
			box-shadow: 0.625rem 0.625rem 1rem rgba(0, 0, 0, 0.1);

			&:before {
				content: "";
				position: absolute;
				bottom: -0.25rem;
				left: 50%;
				transform: translateX(-50%);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0.5rem 0.5rem 0 0.5rem;
				border-color: var(--n0) transparent transparent transparent;
			}
		}
	}
	&.p-tooltip-bottom {
		margin-bottom: -0.625rem;

		.p-tooltip-text {
			box-shadow: 0.625rem -0.625rem 1rem rgba(0, 0, 0, 0.1);

			&:before {
				content: "";
				position: absolute;
				top: -0.25rem;
				left: 50%;
				transform: translateX(-50%);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 0.5rem 0.5rem 0.5rem;
				border-color: transparent transparent var(--n0) transparent;
			}
		}
	}
	.p-tooltip-text {
		background: var(--n0);
		border-radius: 0.5rem;
		padding: 1rem 1rem 1rem 1rem;

		p {
			font-size: 0.8125rem;
			line-height: 1.1375rem;
			margin-bottom: 0;
			color: var(--n200);

			+ p {
				margin-top: 1rem;
			}
		}
	}
}
.nowrap {
	max-width: none;

	.p-tooltip-text {
		white-space: nowrap;
		font-weight: 400;
		font-size: 0.6875rem;
		padding: 0.5rem 0.75rem;

		em {
			&:before {
				width: auto;
			}
		}
	}
}
