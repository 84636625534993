.p-tabmenu {
  margin-bottom: 1.25rem;

  .p-tabmenu-nav {
    background:none;
    border-bottom: .0625rem solid var(--b50);

    .p-tabmenuitem {
      color:var(--n500);

      .p-menuitem-link {
        background:none;
        border-bottom: .0625rem solid var(--b50);
        margin-bottom: -0.0625rem;
        font-size: 1rem;
        color:var(--n500);

        @media (min-width: $width-md) {
          padding: 1rem 2rem;
        }
        &:hover {
          color:var(--b400);
        }
        &:focus {
          box-shadow: none !important;
        }
      }
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            .p-menuitem-link {
              color:var(--b400);
              background:none;
              border-color:var(--b50);
            }
          }
        }
      }
      &.p-highlight {
        .p-menuitem-link {
          color:var(--b400);
          background:none;
          border-color:var(--b400);
        }
      }
    }
  }
  &.tabmenu {
    margin-bottom: 1rem;

    .p-tabmenu-nav {
      justify-content: center;
      border:0 none;

      .p-tabmenuitem {
        .p-menuitem-link {
          padding:0 1rem;
          height: 3rem;
          line-height: 3rem;
          border:0 none;
          border-radius: 1.5rem;
          background: var(--b20);
          color:var(--b400);
          margin: 0 .5rem;
        }
        &.p-highlight {
          .p-menuitem-link {
            background:var(--b400);
            color:var(--n0);
          }
        }
        &.p-disabled {
          .p-menuitem-link {
            opacity: 50%;
            cursor: not-allowed !important;
            pointer-events: auto;
          }
        }
      }
    }
  }
}
