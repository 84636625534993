.table-search {
  margin-bottom: 1rem;
  
  .p-input-icon-right {
    width:100%;
    display: block;
    max-width:34.375rem;

    i {
      margin-right: .3125rem;
    }
  }
  .toggle-archive {
    display:inline-flex;
    align-items: center;
    justify-content: flex-end;
    width:100%;
    margin-top: .75rem;

    p {
      margin:0 .8125rem 0 0;
      font-size: .8125rem;
      color: var(--n400);
    }
    p-inputswitch {
      height: 1.75rem;
    }
  }
}