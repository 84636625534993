#list-days {
	margin-top: 1rem;
	margin-bottom: 1.5rem;

	h5 {
		margin-bottom: 1rem;
	}
	ul {
		margin: 0;
		padding: 0;
		overflow: auto;
		max-height: 18.75rem;

		@media (min-width: 61.9375rem) {
			max-height: 25.9375rem;
		}
		li {
			list-style: none;
			display: flex;
			justify-content: space-between;
			padding-bottom: 0.6875rem;

			&:not(:first-child) {
				border-top: 0.0625rem solid var(--n40);
				padding-top: 0.6875rem;
			}
			> div {
				display: inline-flex;
				align-items: center;
			}
			.left {
				padding-right: 0.625rem;
			}
			span {
				white-space: nowrap;

				&.day {
					width: 3.5rem;
					color: var(--n200);
				}
				&.startday {
					font-weight: bold;
				}
			}
			.status {
				&.tracked {
					color: var(--g300);
				}
				&.non-working-tracked {
					color: var(--o200);
				}
				&.off,
				&.non-working {
					color: var(--n100);
				}
			}
			.action {
				width: 1.875rem;
				color: var(--n100);
        text-align: right;

				.p-button {
					color: var(--n100);
					width: 1.5rem;
          text-align: center;
				}
			}
		}
	}
}
