.sd-avatar {
  max-width:100%;
  border-radius:50%;

  &.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 1.25rem;
    font-weight: $medium;
    color: var(--n500);
  }
  &.p-avatar-lg {
    width: 1.25rem;
    height: 1.25rem;
    font-size: .625rem;
    font-weight: $medium;
    color: var(--n500);
  }
  &.group {
    background: var(--b20);
    color: var(--b400);
  }
}
.ma-details-avatar {
  .sd-avatar {
    &.p-avatar-xl {
      font-size: 2rem;
    }
  }
}
.sd-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 1.25rem;
  font-weight: $medium;
}
.sd-avatar-sm {
  font-size: 10px;
  font-weight: $medium;
  display: block;
}