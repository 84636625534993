.sd-dialog {
  background: var(--n0);
  box-shadow: 0px 0.5rem 1.25rem 0.25rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  width: 100%;
  max-width: 37.5rem;

  &.size-sm {
    max-width: 28.75rem;
  }
  .p-dialog-header {
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 1rem 0.625rem 1rem 1.25rem;

    @media (min-width: $width-sm) {
      padding: 1.5rem 1.75rem 1.5rem 2.5rem;
    }
    .p-dialog-title {
      color: var(--n600);
      font-size: 1.25rem;

      @media (min-width: $width-sm) {
        font-size: 1.5rem;
      }
    }
    .p-dialog-header-icon {
      color: var(--n60);
    }
  }
  .p-dialog-content {
    border-radius: 0 0 0.5rem 0.5rem;
    padding: 0;
  }
  &.inc-subtext {
    .p-dialog-header {
      padding-bottom: 0;
    }
    .p-dialog-content {
      .subtext {
        margin: 0;
        padding: 0 1.25rem 1.5rem 1.25rem;
        font-size: 0.8125rem;

        @media (min-width: $width-sm) {
          padding: 0 1.75rem 1.5rem 2.5rem;
        }
      }
    }
    .dialog-container {
      padding-top: 1.875rem;

      .subtitle {
        margin-top: -1.375rem;
        margin-bottom: 1.625rem;
      }
    }
  }
  .dialog-container {
    border: 0 none;
    padding: 0 1.25rem;

    @media (min-width: $width-sm) {
      padding: 0 2.5rem;
      margin-bottom: 2.5rem;
    }
    h5 {
      margin-bottom: 0.8125rem;
    }
    ul {
      &:not(.radio-list) {
        margin: 0 0 1.5rem 0;
        padding: 0 0 0 1.875rem;

        li {
          list-style-type: disc;
        }
      }
      &.radio-list {
        margin-bottom: 1.5rem;
      }
      &.day-list {
        margin: 0 0 1.625rem 0;
        padding:0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media (min-width: $width-sm) {
          margin: 0;
        }
        li {
          padding: .25rem;
          cursor: pointer;
          list-style-type: none;

          strong {
            display: inline-block;
            width: 2.125rem;
            height: 2.125rem;
            line-height: 1.875rem;
            border: .125rem solid var(--g300);
            border-radius: 50%;
            color: var(--g300);
            font-size: 1rem;
            background: var(--n20);

            @media (min-width: $width-sm) {
              width: 3rem;
              height: 3rem;
              line-height: 2.75rem;
            }
          }
          &.selected {
            strong {
              background: var(--g300);
              color: var(--n0);
            }
          }
          &:hover {
            strong {
              background: var(--g200);
              color: var(--n0);
              border-color: var(--g200);
            }
          }
        }
      }
    }
    p {
      margin-bottom: 0.6875rem;
    }
    .form-dialog {
      margin: 0 -1.25rem;

      @media (min-width: $width-sm) {
        margin: 0 -2.5rem;
      }

      .form-footer {
        margin: 0;
      }
    }
    .form-footer {
      margin: 0 -1.25rem -3.25rem -1.25rem;
    }
  }
  .dialog-footer {
    border: 0 none;
    border-top: 0.0625rem solid var(--n40);
    text-align: right;
    padding: 1.625rem 1.25rem;

    @media (min-width: $width-sm) {
      padding: 1.5rem 2.5rem;
    }
    &.text-right {
      text-align: right;

      .btn-sd {
        margin-left: 0.5rem;
      }
    }
  }
  &.rating-dialog {
    max-width: 35rem;

    .p-dialog-header {
      padding: 0;

      &-icons {
        position: absolute;
        top: .625rem;
        right: .625rem;
      }
    }
    .p-dialog-content {
      border-radius: 0.5rem;
      padding-top: 3rem;
      overflow: hidden;

      h4 {
        line-height: 1.5rem;
        font-weight: $medium;
        margin-bottom: 2.125rem;
      }
      .p-field {
        margin-bottom: 0;
      }
      .rating-comment {
        margin-top: 1.4375rem;

        @media (min-width: $width-md) {
          margin-top: 2.875rem;
        }
        h5 {
          font-weight: $medium;
          margin-bottom: 0.0625rem;
        }
        p {
          margin-bottom: 0.625rem;
          font-size: .8125rem;
          line-height: 1.125rem;
          color: var(--n200);
        }
        .p-inputtext {
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: $regular;
          color: var(--n600);
        }
      }
      .p-buttonset {
        .p-button {
          display: inline-block;
          background: none;
          border: 0 none;
          border-radius: 0;
          margin-left: 1.375rem;
          margin-right: 1.375rem;
          padding: 0;
          width: auto;
          overflow: visible;

          .rating-option {
            .rating-img {
              width: 4rem;
              height: 4rem;
              background: var(--n0);
              border-radius: 50%;
              display: flex;
              align-items: center;
              box-shadow: 0rem 0.5rem 1.25rem 0.25rem rgba(0, 0, 0, 0.1);
              margin-bottom: 0.75rem;
  
              img {
                width: 1.5rem;
                height: 1.5rem;
                margin: 0 auto;
              }
            }
            label {
              margin: 0;
              font-size: .75rem;
              font-weight: $regular;
            }
            &.selected {
              .rating-img {
                img {
                  width: 3.5rem;
                  height: 3.5rem;
                }
              }
              label {
                font-weight: $bold;
                color: var(--b400);
              }
            }
          }  
        }
      }
    }
  }
  &.no-margin-btm {
    .dialog-container {
      margin-bottom: 0;
    }
  }
  &.calendar-dialog {
    .profile {
      display: flex;
      background: var(--n20);
      padding: 1rem 1.25rem;
      align-items: center;
      
      @media (min-width: $width-sm) {
        padding: 1rem 2.5rem;
      }
      &-avatar {
        padding-right: .75rem;
    
        img,
        .p-avatar {
          width: 1.75rem;
          height: 1.75rem;

          .p-avatar-text {
            font-size: .875rem;
          }
        }
      }
      &-details {
        padding-right: 1rem;
        
        p {
          font-size: .875rem;
          line-height: 1.25rem;
          color: var(--n500);
          margin:0;
    
          small {
            display: block;
            font-size: .75rem;
            line-height: 1rem;
            color: var(--n200);
          }
          strong {
            display: inline-block;
            height: 1.125rem;
            padding: 0 .25rem;
            line-height: 1.125rem;
            font-size: .625rem;
            color: var(--n0);
            background: var(--b400);
            border-radius: .25rem;
            font-weight: 400;
          }
        }
      }
      &-action {
        margin-left: auto;
    
        a {
          cursor: pointer;
        }
      }
    }
    .workspace {
      display: flex;
      padding: 1rem 0;
      align-items: center;
    
      &-avatar {
        width: 3.5rem;
        height: 2.5rem;
        padding-right: 1rem;
    
        .p-avatar {
          &.p-avatar-lg {
            width: 2.5rem;
            height: 2.5rem;
          }
        }
      }
      &-name {
        padding-right: 1rem;
        position: relative;
        
        h4 {
          color: var(--n600);
          font-size: 1rem;
          line-height: 1.5rem;
          margin:0;
  
          strong {
            display: inline-block;
            height: 1.125rem;
            padding: 0 .25rem;
            line-height: 1.125rem;
            font-size: .625rem;
            color: var(--n0);
            background: var(--b400);
            border-radius: .25rem;
            font-weight: 400;
          }
        }
        p {
          font-size: .875rem;
          line-height: 1.25rem;
          margin:0;
          font-weight: 500;
          color: var(--n100);
    
          small {
            display: block;
            font-size: .75rem;
            line-height: 1rem;
            
          }
        }
      }
      &-action {
        margin-left: auto;
    
        a {
          cursor: pointer;
          color: var(--n200);
          font-weight: bold;
  
          i {
            margin-left: .1875rem;
          }
        }
      }
    }
    .dialog-content {
      padding: .625rem 1.25rem;
      
      @media (min-width: $width-sm) {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
      }
    }
    .form-input {
      padding:1.5rem 1.25rem 1rem 1.25rem;
      margin:0;

      @media (min-width: $width-sm) {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
      }
      h6 {
        text-transform: none;
        font-size: .875rem;
        line-height: normal;
        margin-bottom: .5rem;
      }
      .p-field-checkbox {
        margin-bottom: .5rem;

        label {
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5rem;
          color: var(--n400);
        }
        .p-checkbox-box {
          &.p-highlight {
            background-color: var(--g300);
            border-color: var(--g300);
          }
        }
      }
    }
    .form-footer {
      padding-left:1.25rem;
      padding-right:1.25rem;
      margin:0;

      @media (min-width: $width-sm) {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
      }
    }
  }
}

