/**
 * @license
 * MyFonts Webfont Build ID 4040893, 2021-04-18T19:06:18-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Larsseit-Light by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/larsseit/light/
 * 
 * Webfont: Larsseit-LightItalic by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/larsseit/light-italic/
 * 
 * Webfont: Larsseit-Regular by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/larsseit/regular/
 * 
 * Webfont: Larsseit-RegularItalic by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/larsseit/italic/
 * 
 * Webfont: Larsseit-Medium by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/larsseit/medium/
 * 
 * Webfont: Larsseit-MediumItalic by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/larsseit/medium-italic/
 * 
 * Webfont: Larsseit-Bold by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/larsseit/bold/
 * 
 * Webfont: Larsseit-BoldItalic by Type Dynamic
 * URL: https://www.myfonts.com/fonts/typedynamic/larsseit/bold-italic/
 * 
 * 
 * Webfonts copyright: Copyright © 2021 by Nico Inosanto. All rights reserved.
 * 
 * © 2021 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3da8bd");
  

@font-face {
  font-family: "Larsseit";
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  font-display: swap;
  src: url('/assets/fonts/larsseit/LarsseitLight/font.woff2') format('woff2'), url('/assets/fonts/larsseit/LarsseitLight/font.woff') format('woff');
}
@font-face {
  font-family: "Larsseit";
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
  font-display: swap;
  src: url('/assets/fonts/larsseit/LarsseitLightItalic/font.woff2') format('woff2'), url('/assets/fonts/larsseit/LarsseitLightItalic/font.woff') format('woff');
}
@font-face {
  font-family: "Larsseit";
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('/assets/fonts/larsseit/Larsseit/normal_normal_normal.woff2') format('woff2'), url('/assets/fonts/larsseit/Larsseit/normal_normal_normal.woff') format('woff');
}
@font-face {
  font-family: "Larsseit";
  font-style: italic;
  font-weight: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('/assets/fonts/larsseit/Larsseit/italic_normal_normal.woff2') format('woff2'), url('/assets/fonts/larsseit/Larsseit/italic_normal_normal.woff') format('woff');
}
@font-face {
  font-family: "Larsseit";
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
  src: url('/assets/fonts/larsseit/LarsseitMedium/font.woff2') format('woff2'), url('/assets/fonts/larsseit/LarsseitMedium/font.woff') format('woff');
}
@font-face {
  font-family: "Larsseit";
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
  src: url('/assets/fonts/larsseit/LarsseitMediumItalic/font.woff2') format('woff2'), url('/assets/fonts/larsseit/LarsseitMediumItalic/font.woff') format('woff');
}
@font-face {
  font-family: "Larsseit";
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  font-display: swap;
  src: url('/assets/fonts/larsseit/Larsseit/normal_normal_bold.woff2') format('woff2'), url('/assets/fonts/larsseit/Larsseit/normal_normal_bold.woff') format('woff');
}
@font-face {
  font-family: "Larsseit";
  font-style: italic;
  font-weight: bold;
  font-stretch: normal;
  font-display: swap;
  src: url('/assets/fonts/larsseit/Larsseit/italic_normal_bold.woff2') format('woff2'), url('/assets/fonts/larsseit/Larsseit/italic_normal_bold.woff') format('woff');
}


