.alert {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  position: relative;

  p {
    margin: 0 !important;
    font-size: 0.8125rem;
  }
  &.error {
    background: var(--o50);
    border: 0.0625rem solid var(--o100);

    p {
      i {
        color: var(--o100);
        margin-right: 0.5rem;
        vertical-align: middle;
        margin-top: -0.1875rem;
      }
    }
  }
  &.with-icon {
    padding: 1rem 0.5rem 1rem 3rem;
    margin-bottom: 1.25rem;
    p {
      line-height: 1.5rem;

      i {
        position: absolute;
        left: 1rem;
        top: 1rem;

        &:before {
          font-size: 1.5rem;
        }
      }
    }
  }
  &.success {
    background: var(--g50);
    border: 0.0625rem solid var(--g200);
  }
  &.warning {
    background: var(--y50);
    border: 0.0625rem solid var(--y60);
    p {
      i {
        color: var(--y60);
      }
    }
  }
  &.notice {
    background: var(--lb50);
    border: 0.0625rem solid var(--lb60);

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      i {
        color: var(--lb60);
      }
    }
  }
}
.alert-container {
  margin-bottom: 1.25rem;
  padding-right: 5rem;
  position: relative;
  width: 100%;

  .alert {
    margin-bottom: 0;

    input {
      padding:0;
      margin:0;
      height:auto;
      background: var(--lb50);
      border:0 none;
    }
  }
  .btn-side {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    width: 4.25rem;

    .btn {
      height: 100%;
      width: 100%;
      padding: 0;
      font-size: 1.25rem;
    }
  }
}
