.add_form {
  position:relative;
  padding-right:90px;
  max-width:525px;

  @media (min-width: $width-xs) {
    padding-right:120px;
  }
  input {
    @include placeholder {
      color:#D1D1D1;
      opacity:1;
    }
    @media (min-width: $width-sm) {
      height:52px;
      padding-left:15px;
    }
  }
  .button {
    width:80px;
    text-align:center;
    position:absolute;
    right:0;
    top:0;
    height:44px;
    font-size:14px;

    @media (min-width: $width-xs) {
      width:110px;
    }
    @media (min-width: $width-sm) {
      height:52px;
      font-size:18px;
    }
  }
  &.add_team {
    padding-right:0;
    max-width:390px;

    .dropdown_item {
      margin-bottom:1rem;

      .dd {
        height:44px;
        line-height:44px;
        padding:0 30px 0 10px;
        background:var(--n0);
        display:block;
        font-size:14px;
        color:#D1D1D1;
        box-shadow:0px 1px 2px 0 rgba(0, 0, 0, 0.16);
        position:relative;

        @media (min-width: $width-sm) {
          height:52px;
          line-height:52px;
          padding-left:15px;
        }

        i {
          position:absolute;
          right:10px;
          top:50%;
          transform: translateY(-50%);
          color:#A3A3A3;
        }
      }
      .dropdown-pane {
        top:100%;
        left:0;
        right:0;
        max-width:100%;
        width:100%;
        border-radius:10px;overflow:hidden;
        margin-top:1px;
        padding-bottom:0;

        .checkboxes {
          li {
            border:0 none;
          }
        }
        .button {
          width:100%;
          background:#6C64C9;
          position:static;
          border-radius:0 0 10px 10px;
          padding:0;
          height:50px;
          line-height:50px;
          font-size:14px;
        }
      }
    }
    .dropdown_search {
      position:relative;

      input {
        padding-right:44px;
        font-size:14px;
        margin:0;

        @media (min-width: $width-sm) {
          height:60px;
          padding-right:60px;
          padding-left:20px;
          font-size:18px;
        }

        @include placeholder {
          color:#9E9AAC;
          opacity:1;
        }
      }
      i {
        position:absolute;
        right:10px;
        top:50%;
        transform: translateY(-50%);

        @media (min-width: $width-sm) {
          right:20px;
        }
      }
    }
    .cb {
      padding-left:40px;
    }
  }
}